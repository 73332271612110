@import 'variables';
// @import '~bootstrap/scss/bootstrap';
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
// @import 'sweetalert2/src/sweetalert2.scss';

//breakpoints
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

@media (min-width: $small) {
  .input-half {
    width: 50%;
  }
}

//tags
h2 {
  font-size: 1.625rem;
  margin-bottom: 1rem;
}

h3 {
  font-weight: 600;

  &.plain {
    font-weight: 400;
    margin-bottom: 0;
  }
  &.subtitle {
    color: $black-90;
  }
}

h4 {
  font-weight: 600;

  &.plain {
    font-weight: 400;
    margin-bottom: 0;
  }
}

//classes

// .accordion-button:not(.collapsed) {
//   color: #259acb;
//   background-color: #eaf7fc;
//   box-shadow: inset 0 -1px 0 rgb(20 18 22 / 13%);
// }

.accordion-button:not(.collapsed) {
  box-shadow: initial;
}

.accordion-button {
  &.not-due {
    color: $black-40;
  }
}

.af-name-header {
  margin-top: 0.3rem;

  @media (min-width: $small) {
    margin-top: 0px;
  }
}

.alert-light {
  color: $body-color;
  background-color: rgba(44, 170, 226, 0.05);
  border: 1px solid rgba(33, 33, 33, 0.2);
  // background-color: #cff4fc;
  // border-color: #b6effb;
}

.alert-warning {
  color: $warning-2;
  background-color: $warning-4;
  border: 0;
}

.alert-primary {
  color: $alt-blue;
  background-color: $alt-blue-2;
  border: 0;
}

.alert-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.asteriskField {
  margin-left: 8px;
  color: $red;
}

.avatar {
  background-color: $retention-blue-20;
  color: $retention-blue;
}

.background-blue-10 {
  background-color: $retention-blue-10;
}

.badge-primary,
.badge-secondary,
.badge-warning,
.badge-danger {
  border-radius: 50rem;
  padding: 9px 23px;
}

.badge-primary {
  border: 1px solid $retention-blue;
  color: $retention-blue;
}

.badge-secondary {
  border: 1px solid $black-50;
  color: $black-50;
}

.badge-warning {
  border: 1px solid $warning-1;
  background: $warning-1;
  color: white;
}

.badge-danger {
  border: 1px solid $red;
  background: $red;
  color: white;
}

.base-container,
.base-container-medium,
.base-container-small {
  width: 100%;
}

.base-container-medium {
  max-width: 576px;
  width: 100%;
}

.base-container-small {
  max-width: 400px;
  width: 100%;
}

.big-money {
  font-size: 4rem;
  font-weight: 900;
  color: $money;
}

.big-money-400 {
  font-size: 13.5vw;

  @media (min-width: 424px) {
    font-size: 3.6rem;
  }

  line-height: 1;
}

.big-screens {
  color: $retention-blue;
  font-weight: 400;

  h1 {
    font-size: calc(3rem + 3vw);
    color: $retention-blue;
    font-weight: 400;
    line-height: 7.5rem;
  }

  h2 {
    font-size: calc(2.5rem + 1.3vw);
    color: $retention-blue;
    font-weight: 300;
  }

  div {
    font-size: calc(1rem + 0.5vw);
    color: $retention-blue;
    font-weight: 400;
    color: white;
  }

  i {
    color: rgba(white, 0.15);
  }

  .positive {
    color: $positive-1;
  }

  .warning {
    color: $warning-3;
  }

  .negative {
    color: $negative-1;
  }

  .positive-shadow {
    box-shadow: 0px 0px 25px 3px $positive-1;
  }

  .warning-shadow {
    box-shadow: 0px 0px 25px 3px $warning-3;
  }

  .negative-shadow {
    box-shadow: 0px 0px 25px 3px $negative-1;
  }

  .card {
    border-radius: 5px;
    background-color: rgba($dark-blue, 0.6);
    height: calc(12rem + 3vw);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
  }

  .chart {
    background-color: rgba($dark-blue, 0.6);
    padding: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  }

  .pill {
    border-radius: 39px;
    border: 1px solid;
    padding-top: 3px;
    padding-bottom: 7px;
    padding-left: 24px;
    padding-right: 24px;

    &.positive {
      color: $positive-1;
      border-color: $positive-1;

      &.filled {
        background-color: $positive-1;
        color: $dark-blue !important;
      }
    }

    &.warning {
      color: $warning-3;
      border-color: $warning-3;

      &.filled {
        background-color: $warning-3;
        color: $dark-blue;
      }
    }

    &.negative {
      color: $negative-1;
      border-color: $negative-1;

      &.filled {
        background: $negative-1;
        color: $dark-blue;
      }
    }
  }
}

.border-lg-end {
  @media (min-width: $large) {
    border-right: 1px solid $black-20;
  }
}

.box-blue {
  text-align: center;
  background: $retention-blue;
  color: white;
  line-height: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;
}

.btn .fa-arrow-right {
  margin-left: 0.75rem;
  font-weight: bold;
}

.btn-back {
  color: $retention-blue;
}

.btn-outline-primary {
  &:hover {
    color: white;
  }
}

.btn-outline-light-blue {
  background: $blue-15;
}

.btn-link:focus {
  box-shadow: none;
}

.btn-primary {
  color: white;

  &:hover,
  &:focus,
  &:disabled,
  &.disabled {
    color: white;
  }
}

.btn-danger {
  color: white;

  &:hover,
  &:focus,
  &:disabled,
  &.disabled {
    color: white;
  }
}

.btn-warning {
  color: white;

  &:hover,
  &:focus,
  &:disabled,
  &.disabled {
    color: white;
  }
}

.btn-submit {
  min-width: 128px;
}

.btn-success {
  color: white;

  &:hover {
    background-color: #16aa52;
    color: white;
  }
}

.card {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  &.card-small .card-body {
    padding: 1rem;
  }
  &.selected .card-body {
    border-bottom: 0.3125rem solid $retention-blue-60;
    padding-bottom: 0.6875rem;
  }
}

.circle-outreach-blue,
.circle-outreach-disabled,
.circle-progress-blue,
.circle-progress-green,
.circle-progress-red,
.circle-progress-outline {
  height: 2rem;
  width: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  flex-shrink: 0;
  border-radius: 50%;
}

.circle-outreach-blue,
.circle-outreach-disabled {
  height: 3rem;
  width: 3rem;
  background-color: $retention-blue;
  border: none
}

.circle-outreach-disabled {
  pointer-events: none;
  opacity: 0.65;
}

.circle-progress-blue {
  background-color: $retention-blue;
}

.circle-progress-green {
  background-color: $money;
}

.circle-progress-red {
  background-color: $negative-1;
}

.circle-progress-outline {
  border: 1px solid $black-40;
  color: $black-40;
}

.color-dot {
  display: inline-block;
  width: 0.6875rem;
  height: 0.6875rem;
  border-radius: 100%;
}

.credit-amount {
  font-size: 36px;
  color: $money;
  font-weight: bold;
}

.customer-card .form-label {
  margin-bottom: 0.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.search-form {
  #div_id_activity_type {
    width: 100%;
    margin-bottom: 0px !important;
  }
}

.dashboard-card {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
  padding: 1.5rem;

  @media (min-width: $small) {
    .border-right {
      border-right: 1px solid $black-30;
    }
  }
}

.dropdown-menu {
  box-shadow: rgb(37 40 43 / 12%) 0px 0px 8px;
  .selected-item {
    background-color: $black-10;
  }
  .highlighted-item {
    color: $retention-blue;
    background-color: $retention-blue-10;
  }
}

.error-img {
  max-width: 762px;
}

.file-drop-zone {
  background-color: $retention-blue-5;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  margin-bottom: 1rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2314121633' stroke-width='2' stroke-dasharray='3%2c7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &.hover {
    background-color: $retention-blue-10;
  }

  &.is-invalid {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23f23a3c' stroke-width='2' stroke-dasharray='3%2c7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  * {
    pointer-events: none;
  }
}

.file-row {
  background-color: $retention-blue-5;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  padding: 1rem;
  gap: 0.5rem;
  word-break: break-all;

  .fa-light {
    color: $black-20;
  }

  .fa-trash-can {
    cursor: pointer;
  }
}

.file-row-alt {
  background-color: $alt-blue-2;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  padding: 1rem;

  .fa-light {
    color: $black-20;
  }

  .fa-trash-can {
    cursor: pointer;
  }
}

.filing-options {
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);

  h1 {
    font-weight: 700;
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.3;
  }
}

.fixed-footer {
  padding: 3.125rem 0;

  a {
    text-decoration: none;
    color: $black-50;

    &:hover {
      text-decoration: underline;
    }
  }
}

.form-label {
  line-height: 1.25;
}

.fw-600 {
  font-weight: 600;
}

.jumbo-header {
  font-size: calc(2.2rem + 1.2vw);

  @media (min-width: $small) {
    font-size: 3.25rem;
  }
}

.line-height-1 {
  line-height: 1;
}

.line-height-120 {
  line-height: 1.2;
}

.line-height-125 {
  line-height: 1.25;
}

.line-height-140 {
  line-height: 1.4;
}

.list-item {
    display: flex;

    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.375rem;
    position: relative;
    line-height: 1.5rem;

    &.list-item-results {
      font-size: 4vw;
      margin-bottom: 5.5vw;

      @media (min-width: 424px) {
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
      }

      color: white;
      white-space: nowrap;
    }

    h3 {
      margin-bottom: 0;
    }

    .list-item-number {
        background-color: $retention-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        color: white;
        flex-shrink: 0;
      }

    .list-item-number,
    .list-item-check {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
    }



    .list-item-check {
      // border: 3px solid $retention-blue;
      width: 2.8125rem;
      height: 2.3125rem;
      background-image: url('/static/app/img/check.svg');
      flex-shrink: 0;
    }

    &.list-item-results .list-item-check {
      width: 1.75rem;
      height: 1.375rem;
      background-image: url('/static/app/img/check-white.svg');
      margin-right: 0.5rem;

      & + div {
        font-weight: bold;
      }
    }

    .list-item-empty {
      margin-right: 1.8125rem;
      width: 2rem;
      height: 2rem;
      border: 3px solid $black-40;

      & + div {
        opacity: 0.7;
      }
    }

    &.list-item-results .list-item-empty {
      margin-right: 0.875rem;
      width: 1.375rem;
      height: 1.375rem;
      border: 2px solid rgba($color: #ffffff, $alpha: 0.5);

      & + div {
        opacity: 0.5;
      }
    }

    .list-item-subtitle {
      color: #999999;
      font-size: 1rem;
      position: absolute;
    }
  }



.mb-3-5 {
  margin-bottom: 1.25rem;
}

.mb-4-5 {
  margin-bottom: 2rem;
}

.mb-4-6 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 4rem;
}

.mb-6-5 {
  margin-bottom: 5rem;
}

.mb-7 {
  margin-bottom: 6.75rem;
}

.p-4-6 {
  padding: 2.5rem;
}

.px-8 {
  padding-left: 7.25rem;
  padding-right: 7.25rem;
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.mini-divider-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;

  .mini-divider {
    width: 282px;
    border-top: 2px solid #31a3dc;
    margin: 0 auto 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.modal-body {
  padding: 1.5rem;

  @media (min-width: $small) {
    padding: 3rem;
  }
}

.ms-4-5 {
  margin-left: 2rem;
}

.money {
  color: $money !important;
}

.irs-delay-header {
    background-color: $dark-blue;
    color: white;
    display: flex;
    height: 200px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5rem;
    margin-bottom: 3rem;
    @media (max-width: 1000px) {
        height: auto;
        align-items: center;
        padding: 2rem
    }
}

.state-header {
    top: 170.83px;
    left: 70%;
    width: 300px;
    position: absolute;
    display: none;
    @media (min-width: 1000px) {
        display: block;
    }
}

.dark-blue {
    color: $dark-blue;
}

.light-grey {
    background-color: #F4F5F8;
}

.navbar {
  background-color: #f5f5f7;
}

.nav-link {
  font-size: 1.125rem;
}

// .progress {
//   border: 1px solid $border-color;
// }

.resource-btn {
  border: 0;
  border-radius: 1px;
  padding: 0;
}

.search-text-box {
  border-radius: 1.5625rem;
  padding: 0.375rem 1.4375rem;
  height: 3rem;
}

.secondary-nav {
  a {
    color: $dark-blue;
    font-size: 1.125rem;
    &:hover {
      color: $dark-blue-70;
    }
  }
  .nav-tab-active {
    border-bottom: 1px solid $dark-blue;
  }
    .dropdown-item:hover,
    .dropdown-item:focus {
      color: $retention-blue;
    }
    .dropdown-item:active {
      color: white;
    }
}

.status {
  color: $black-90;
  font-weight: bold;
}

.status-actions {
  padding: 1rem;
  background: $blue-15;
  border-radius: 0.25rem;
  color: $retention-blue;

  p {
    line-height: 1.3;
  }
}


.due-date-danger-text {
    color: red;
    font-size: large;
    font-weight: bold;
  }


.due-date-warning-text {
    color: #ffaa00;
    font-size: large;
    font-weight: bold;
  }

.status-actions-danger {
  background: rgba($color: #f93232, $alpha: 0.18);
  color: #e93c3c;
}


.status-actions-warning {
  background: rgba($color: $warning-1, $alpha: 0.18);
  color: $warning-2;
}

.status-color-green {
  color: #1ab759;
}

.status-color-red {
  color: #e93c3c !important;
}

.status-color-yellow {
  color: #c28007;
}

.status-info {
  color: $black-90;
  font-weight: 600;
  // overflow-wrap: break-word;
  // truncate instead
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.survey {
  // background-color: #f7f8fa;
  max-width: 576px;
  width: 100%;

  .title {
    margin-bottom: 0.5rem;

    h1 {
      margin-bottom: 0;
    }

    .badge {
      padding: 0.8rem 1.4375rem;
      background-color: #ffffff;
      border: 1px solid $black-10;
      border-radius: 1.0625rem;
      font-size: 0.875rem;
      font-weight: normal;
    }
  }

  .subtitle {
    // color: #333333;
    margin-bottom: 2rem;
  }

  .progress {
    margin-bottom: 2.5rem;
  }

  .list-column {
    margin-right: 5.5vw;

    @media (min-width: 424px) {
      margin-right: 1.625rem;
    }
  }



  .payroll-instructions {
    display: none;
    margin-bottom: 2.5rem;
  }

  .button-bar {
    margin-top: 4rem;
    padding: 2.5rem 0;
    border-color: $black-10 !important;

    .btn,
    .button-bar-right {
      margin-bottom: 0.75rem;
      width: 100%;

      @media (min-width: $small) {
        margin-bottom: 0;
        width: initial;
      }
    }
  }

  .form-check {
    font-size: 1.125rem;
  }

  // input,
  // label {
  //   font-size: 1.375rem;
  // }
  .substantiation {
    margin-bottom: 2rem;
  }

  .docs-review-checklist {
    font-size: 1.375rem;
    font-weight: 600;
  }
}

.table > :not(:first-child) {
  border-top: none;
}

.table-secondary {
  vertical-align: middle;
}

.table-center-data th, .table-center-data td {
  text-align: center;
}

.text-black-30 {
  color: $black-30;
}

.text-black-40 {
  color: $black-40;
}

.text-black-50 {
  color: $black-50;
}

.text-black-60 {
  color: $black-60;
}

.text-black-80 {
  color: $black-80;
}

.text-black-90 {
  color: $black-90;
}

.text-big {
  font-size: 1.375rem;
}

.text-large {
  font-size: 3.5rem;
  line-height: 3.5rem;
}

.text-mid-small {
  font-size: 0.875rem;
}

.text-small {
  font-size: 0.75rem;
}

.border-dashed {
  border: 1px dashed rgba(20, 18, 22, 0.2) !important;
}

.mt-3-negative {
  margin-top: -1rem;
}

.mt-6 {
  margin-top: 4rem;
}

.mt-7 {
  margin-top: 6.75rem;
}

pre.code {
  background-color: $light;
  padding: 1rem;
}

.metadata {
  color: gray;
  font-size: smaller;
}

@media print {
  /* All your print styles go here */
  .print-hide {
    display: none !important;
  }
  .print-show {
    display: block !important;
  }
}

.smaller {
  font-size: 0.75rem;
}

.color-inherit {
  color: inherit !important;
}

.box-radio {
  color: $retention-blue;
  background-color: white;
  border: 1px solid $retention-blue;
  .box-radio-item {
    padding: 0.75rem;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    &:not(:last-of-type) {
      border-right: 1px solid $retention-blue;
    }
    &.selected {
      border-bottom: 0.3125rem solid $retention-blue-60;
      padding-bottom: 0.6875rem;
    }
  }
}
