$retention-blue: #29abe2;
$alt-blue: #2caae2;
$alt-blue-2: rgba($alt-blue, 0.2);
$retention-blue-5: rgba($retention-blue, 0.05);
$retention-blue-10: rgba($retention-blue, 0.1);
$retention-blue-20: rgba($retention-blue, 0.2);
$retention-blue-60: rgba($retention-blue, 0.6);
$dark-blue: #192c57;
$dark-blue-70: rgba($dark-blue, 0.7);
$black: #141216;
$black-012: rgba($black, 0.012);
$black-10: rgba($black, 0.1);
$black-20: rgba($black, 0.2);
$black-30: rgba($black, 0.3);
$black-40: rgba($black, 0.4);
$black-50: rgba($black, 0.5);
$black-60: rgba($black, 0.6);
$black-80: rgba($black, 0.8);
$black-90: rgba($black, 0.9);
$warning-1: #f6a609;
$warning-2: #c28007;
$warning-3: #ffbc1f;
$warning-4: rgba($warning-3, 0.2);
$warning-4: rgba(255, 188, 31, 0.2);
$money: #57cc99;
$positive-1: #2ac769;

// bootstrap overrides
// $blue: #2e69ff;
$blue: $retention-blue;
$body-color: $black-90;
$red: #f23a3c;
$negative-1: #fb4e4e;
$green: #18bd5b;
$light: #e5e7f2;
$yellow: $warning-3;

$blue-15: rgba($blue, 0.15);

$font-size-base: 1rem;
$font-family-base: 'Source Sans Pro', sans-serif;
// $font-size-sm: $font-size-base * 0.875;
// $font-weight-bold: 600;
// $font-weight-bolder: 700;

// $headings-font-weight: $font-weight-bold;

// $dark: $rich-black;

// $body-bg: $background;
// $primary: $blue-jeans;

$accordion-button-active-color: initial;
$accordion-button-active-bg: initial;
$accordion-button-focus-border-color: initial;
$accordion-button-focus-box-shadow: initial;
// $accordion-border-width: 0;
// box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

// $badge-border-radius: 0.3125rem;
$badge-font-weight: 400;

$btn-border-radius: 0.0625rem;
$btn-border-radius-lg: 0.0625rem;
$btn-border-radius-sm: 0.0625rem;
// $btn-font-size: 0.75rem;
// $btn-font-size-sm: 0.75rem;
// $btn-font-weight: $font-weight-bolder;
$btn-padding-y: 0.625rem;
$btn-padding-x: 1.5rem;
$btn-padding-y-lg: 1rem;
// $btn-padding-x-lg: 1rem;

$border-color: $black-20;
$breadcrumb-margin-bottom: 0.25rem;

$card-border-radius: 0;
$card-border-width: 0;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;

$dropdown-link-color: $black-90;
$dropdown-link-hover-bg: $retention-blue-10;
$dropdown-link-hover-color: $retention-blue;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1.375rem;
$dropdown-border-width: 0;
// $dropdown-border-color: $light-rhythm;
// $dropdown-border-radius: 1rem;

// $form-label-font-size: 0.75rem;
// $form-label-font-weight: 700;
$form-label-color: $black-60;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
// $h6-font-size: $font-size-base;
$headings-color: $black;
$headings-font-weight: 700;

$hr-color: $border-color;
$hr-opacity: 1;

// $input-bg: white;
// $input-border-radius: 0.125rem;
// $input-font-size: 0.875rem;
$input-padding-y: 0.625rem;
$input-border-color: $black-20;
$input-group-addon-bg: #fff;
$input-group-addon-padding-x: 1rem;
$input-placeholder-color: $black-40;

$link-decoration: none;

$modal-backdrop-opacity: 0.9;
$modal-dialog-margin-y-sm-up: 3rem;
$modal-inner-padding: 3rem;
$modal-md: 576px;

// $modal-footer-border-width: 0;
// $modal-header-border-width: 0;

$navbar-light-color: $dark-blue;
$navbar-light-hover-color: $dark-blue-70;
// $navbar-nav-link-padding-x: 1.25rem;
$navbar-nav-link-padding-x: 1.75rem;
$navbar-brand-padding-y: 1rem;

$progress-bar-bg: rgba($blue, 0.75);
$progress-border-radius: 0.625rem;
$progress-bg: rgba($black, 0.06);

$table-cell-padding-y: 1.25rem;
$table-cell-padding-x: 1.25rem;
// $table-hover-bg-factor: 0.075;
$table-hover-bg: $retention-blue-10;
// $table-hover-color: $retention-blue-10;

$tooltip-max-width: 270px;

$text-muted: $black-40;

//Added for reference
//sm >= 576px
//md >= 768px
//lg >= 992px
//xl >= 1200px
//xxl >= 1400px
//DEFAULTS
// $container-max-widths: (
//     sm: 540px,
//     md: 720px,
//     lg: 960px,
//     xl: 1140px,
//     xxl: 1320px
// );

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
    xxxxl: 1800px,
    xxxxxl: 2000px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1500px,
    xxxxl: 1700px,
    xxxxxl: 1900px,
);
